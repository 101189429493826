import { signOut } from 'next-auth/react';

export const authenticate = async (
  token: string,
  setAccountData: (obj: any) => {},
  setToken: (token: any) => {},
): Promise<void> => {
  try {
    const authLink = `${process.env.NEXT_PUBLIC_STRAPI}/api/auth/google/callback?access_token=${token}`;
    localStorage.setItem('googleToken', token);

    const response = await fetch(authLink).then(resp => resp.json());

    if (response.jwt) {
      localStorage.setItem('accessToken', response.jwt);
    }

    if (response.user) {
      setAccountData(response.user);
      const accessToken = localStorage.getItem('accessToken');
      setToken(accessToken);
      document.cookie = `accessToken=${encodeURIComponent(
        accessToken,
      )}; path=/; Secure; SameSite=Strict;`;
    }
  } catch (e) {
    console.error(e);
    document.cookie = `accessToken=; path=/; Secure; SameSite=Strict;`;
    localStorage.removeItem('accessToken');
  }
};

export const logout = async (): Promise<void> => {
  signOut({ redirect: false });
  localStorage.removeItem('accessToken');
  localStorage.removeItem('googleToken');
  window.location.reload();
};
