const ru = {
  title: 'Настройки',
  selectTitle: 'Звание',
  usernameTxt: 'Имя пользователя',
  email: 'Электронная почта',
  visible: 'Видно:',
  everyone: 'Всем',
  onlyYou: 'Только мне',
  linkedIn: 'LinkedIn профиль',
  saveBtn: 'Сохранить',
  cancelBtn: 'Отмена',
  usernameValidationMessage:
    'Имя пользователя должно состоять из 6-30 символов (без пробелов)',
  invalidLinkedIn: 'Не верный формат ссылки',
};

export default ru;
