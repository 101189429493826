const en = {
  title: 'Settings',
  selectTitle: 'Select title',
  usernameTxt: 'Username',
  email: 'Email',
  visible: 'Visible to:',
  everyone: 'Everyone',
  onlyYou: 'Only you',
  linkedIn: 'LinkedIn profile',
  saveBtn: 'Save',
  cancelBtn: 'Cancel',
  usernameValidationMessage:
    'Username must be between 6 and 30 characters long and contain no spaces',
  invalidLinkedIn: 'Please enter a valid LinkedIn URL.',
};

export default en;
